import { template as template_1ba55cb2aa3c49a8b42e89dcac281d04 } from "@ember/template-compiler";
const FKText = template_1ba55cb2aa3c49a8b42e89dcac281d04(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
