import { template as template_b1541a2348f843f3ba48fd9cbaa3397d } from "@ember/template-compiler";
const SidebarSectionMessage = template_b1541a2348f843f3ba48fd9cbaa3397d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
